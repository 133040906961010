import { Token, TradeType } from "@shidoglobal/sdk-core";
import { Trade } from "@uniswap/v3-sdk";
import { BigNumber, ethers } from "ethers";
import JSBI from "jsbi";

const MAX_DECIMALS = 4;

function truncateDecimals(value: any, decimals: any) {
  const parts = value.split(".");
  if (parts.length === 2 && parts[1].length > decimals) {
    return `${parts[0]}.${parts[1].substring(0, decimals)}`;
  }
  return value;
}

export function fromReadableAmount(amount: any, decimals: number): any {
  return ethers.utils.parseUnits(
    truncateDecimals(amount.toString(), decimals),
    decimals
  );
}

export function toReadableAmount(rawAmount: number, decimals: number): string {
  return ethers.utils.formatUnits(rawAmount, decimals);
}

export function displayTrade(trade: Trade<Token, Token, TradeType>): string {
  return `${trade.inputAmount.toExact()} ${
    trade.inputAmount.currency.symbol
  } for ${trade.outputAmount.toExact()} ${trade.outputAmount.currency.symbol}`;
}
