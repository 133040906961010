export const NFT_DESCRIPTOR_ADDRESS =
  process.env.REACT_APP_NFT_DESCRIPTOR_ADDRESS || "";
export const POSITION_DESCRIPTOR_ADDRESS =
  process.env.REACT_APP_POSITION_DESCRIPTOR_ADDRESS || "";
export const POSITION_MANAGER_ADDRESS =
  process.env.REACT_APP_POSITION_MANAGER_ADDRESS || "";
export const WSHIDO_ADDRESS = process.env.REACT_APP_WSHIDO_ADDRESS || "";
export const FACTORY_ADDRESS = process.env.REACT_APP_FACTORY_ADDRESS || "";
export const QUOTER_CONTRACT_ADDRESS =
  process.env.REACT_APP_QUOTER_CONTRACT_ADDRESS || "";
export const SWAP_ROUTER_ADDRESS =
  process.env.REACT_APP_SWAP_ROUTER_ADDRESS || "";
export const MULTI_CALL = process.env.REACT_APP_MULTI_CALL || "";
export const TICK_LENS = process.env.REACT_APP_TICK_LENS || "";
export const CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID) || 9008;
export const SWAP_ROUTER_V2 = process.env.REACT_APP_SWAP_ROUTER_V2 || "";
export const MAX_FEE_PER_GAS = process.env.REACT_APP_MAX_FEE_PER_GAS || "";
export const MAX_PRIORITY_FEE_PER_GAS =
  process.env.REACT_APP_MAX_PRIORITY_FEE_PER_GAS || "";
