import { Button, Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { PoolType } from "../utils/types";
import { useNavigate } from "react-router-dom";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { fetchTokenImages, getTokenImage } from "../api/ApiCalls";
import { formatLiquidity, formatNumberUniversal } from "../utils";
import Web3 from "web3";
import defaultSVG from "../assets/DefaultSVG.svg";
interface propType {
  Pool: PoolType;
  index: number;
}

const PoolCards = (props: propType) => {
  const prop = props;
  const navigate = useNavigate();
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [token0Image, setToken0Image] = useState<string>(defaultSVG);
  const [token1Image, setToken1Image] = useState<string>(defaultSVG);
  const [liquidityUSD, setLiquidityUSD] = useState<string>("");

  const getTokenImages = async () => {
    try {
      const tokenImages = await fetchTokenImages([
        prop.Pool.token0.id,
        prop.Pool.token1.id,
      ]);

      setToken0Image(
        tokenImages?.[Web3.utils.toChecksumAddress(prop.Pool.token0.id)]
      );
      setToken1Image(
        tokenImages?.[Web3.utils.toChecksumAddress(prop.Pool.token1.id)]
      );
    } catch (error) {
      console.error("Error fetching token images", error);
    }
  };

  useEffect(() => {
    Promise.all([getTokenImages()]);
  }, [prop.Pool]);

  return (
    <>
      <Row
        style={{
          backgroundColor: "#37373C",
          borderRadius: "8px",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Row style={{ alignItems: "center", marginBottom: "36px" }}>
          <Col
            style={{
              position: "relative",
            }}
          >
            <img
              src={token0Image}
              style={{ position: "relative", zIndex: 1 }}
              className="icon-size-pool-card"
            />
            <img
              src={token1Image}
              style={{ position: "absolute", left: "32px" }}
              className="icon-size-pool-card"
            />
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "60px",
            }}
          >
            <h5
              style={{
                margin: 0,
                fontSize: sm ? "24px" : "20px",
                fontWeight: "700",
                color: "white",
              }}
            >
              {prop.Pool.token0.symbol
                ? `${prop.Pool.token0.symbol} / ${prop.Pool.token1.symbol}`
                : "Coin / Coin"}
            </h5>
          </Col>
        </Row>

        <Row style={{ justifyContent: "space-between", marginBottom: "16px" }}>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            span={12}
          >
            <h6
              style={{
                margin: 0,
                fontSize: "14px",
                fontWeight: "600",
                color: "#9B9CA3",
                marginBottom: "5px",
              }}
            >
              Volume (24H)
            </h6>
            <h5
              style={{
                margin: 0,
                fontSize: "16px",
                fontWeight: "700",
                color: "white",
              }}
            >
              ${formatNumberUniversal(String(prop.Pool.volumeUSD))}
            </h5>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            span={12}
          >
            <h6
              style={{
                margin: 0,
                fontSize: "14px",
                fontWeight: "600",
                color: "#9B9CA3",
                marginBottom: "5px",
              }}
            >
              Liquidity
            </h6>
            <h5
              style={{
                margin: 0,
                fontSize: "16px",
                fontWeight: "700",
                color: "white",
              }}
            >
              ${formatNumberUniversal(prop.Pool.totalValueLockedUSD)}
            </h5>
          </Col>
        </Row>

        <Row style={{ marginBottom: "24px" }}>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            span={12}
          >
            <h6
              style={{
                margin: 0,
                fontSize: "14px",
                fontWeight: "600",
                color: "#9B9CA3",
                marginBottom: "5px",
              }}
            >
              Fees (%)
            </h6>
            <h5
              style={{
                margin: 0,
                fontSize: "16px",
                fontWeight: "700",
                color: "white",
              }}
            >
              {Number(prop.Pool.feeTier) / 10000}%
            </h5>
          </Col>
        </Row>

        <Button
          style={{ width: "100%" }}
          onClick={() => {
            navigate(`/pool/${prop.Pool.id}`);
          }}
          type="primary"
        >
          View Pool
        </Button>
      </Row>
    </>
  );
};

export default PoolCards;

export const DummyPoolCard = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  return (
    <Row
      style={{
        backgroundColor: "#37373C",
        borderRadius: "8px",
        padding: "24px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Row style={{ alignItems: "center", marginBottom: "36px" }}>
        <Col
          style={{
            position: "relative",
            display: "flex",
          }}
        >
          <div
            style={{
              position: "relative",
              zIndex: 1,
              backgroundColor: "#42424a",
            }}
            className="icon-size-pool-card"
          />
          <div
            style={{
              position: "absolute",
              left: "32px",
              backgroundColor: "#42424a",
            }}
            className="icon-size-pool-card"
          />
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "60px",
            height: "42px",
            backgroundColor: "#42424a",

            flex: 1,
            borderRadius: "8px",
          }}
        ></Col>
      </Row>

      <Row
        style={{
          justifyContent: "space-between",
          marginBottom: "16px",
          gap: "24px",
        }}
      >
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            height: "42px",
            backgroundColor: "#42424a",
            flex: 1,
          }}
        ></Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            height: "42px",
            backgroundColor: "#42424a",
            flex: 1,
          }}
        ></Col>
      </Row>

      <Row
        style={{
          justifyContent: "space-between",
          marginBottom: "16px",
          gap: "24px",
        }}
      >
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            height: "42px",
            backgroundColor: "#42424a",
            flex: 1,
          }}
        ></Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            height: "42px",
            backgroundColor: "#42424a",
            flex: 1,
          }}
        ></Col>
      </Row>

      <div
        style={{
          width: "100%",
          height: "42px",
          backgroundColor: "#42424a",
          borderRadius: "8px",
        }}
        onClick={() => {}}
      ></div>
    </Row>
  );
};
